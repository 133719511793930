import * as PIXI from 'pixi.js';

import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH } from '../config';

export const FREE_SPINS_TITLE_POSITION_X = 0;
export const FREE_SPINS_TITLE_POSITION_Y = -95;
export const FREE_SPINS_TITLE_WIDTH = 0;
export const FREE_SPINS_TITLE_HEIGHT = 0;
export const FREE_SPIN_ANIMATION_SCALE = 1.3;
export const FREE_SPIN_ANIMATION_DELAY = 500;
export const FREE_SPIN_ANIMATION_LOOP = false;
export const FREE_SPIN_SPIN_ANIMATION_SIZE = 1720;

export const MULTIPLIER_POSITION_X = GAME_CONTAINER_WIDTH / 2;
export const MULTIPLIER_POSITION_Y = GAME_CONTAINER_HEIGHT / 2;

export const MULTIPLIER_TEXT_POSITION_X = 10;
export const MULTIPLIER_TEXT_POSITION_Y = -150;

export const ANIMATIONS_STEPS = ['in'];
export const ANIMATIONS = {
  in: 0,
};

const font = 'NotoSans-SemiCondensedBold';

export const textStyle: Partial<PIXI.ITextStyle> = {
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: ['#f6ffd6', '#fff0f0', '#ffbe33', '#793416', '#feb043'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: font,
  fontSize: 200,
  fontStyle: 'normal',
  fontVariant: 'normal',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#000000',
  strokeThickness: 6,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
};

export const createBounceAnimation = (object: PIXI.Text): AnimationChain => {
  const animationChain = new AnimationChain();
  const delay = new Tween({
    object,
    target: -130,
    property: TweenProperties.Y,
    propertyBeginValue: -130,
    duration: 0.1,
  });
  const scaleUpX = new Tween({
    object: object.scale,
    property: TweenProperties.X,
    propertyBeginValue: 1,
    target: 1.25,
    duration: 475.0,
  });
  const scaleUpY = new Tween({
    object: object.scale,
    property: TweenProperties.Y,
    propertyBeginValue: 1,
    target: 1.25,
    duration: 475.0,
  });
  const scaleDownX = new Tween({
    object: object.scale,
    property: TweenProperties.X,
    propertyBeginValue: 1.25,
    target: 1,
    duration: 425.0,
  });
  const scaleDownY = new Tween({
    object: object.scale,
    property: TweenProperties.Y,
    propertyBeginValue: 1.25,
    target: 1,
    duration: 425.0,
  });
  const scaleUp = new AnimationGroup();
  const scaleDown = new AnimationGroup();
  scaleUp.addAnimation(scaleUpX);
  scaleUp.addAnimation(scaleUpY);
  scaleDown.addAnimation(scaleDownX);
  scaleDown.addAnimation(scaleDownY);

  const stopY = new Tween({
    object,
    target: -130,
    property: TweenProperties.Y,
    propertyBeginValue: -130,
    duration: 550,
  });
  animationChain.appendAnimation(delay);
  animationChain.appendAnimation(scaleUp);
  animationChain.appendAnimation(scaleDown);
  animationChain.appendAnimation(stopY);

  delay.addOnComplete(() => {
    object.visible = true;
  });

  animationChain.addOnSkip(() => {
    object.visible = false;
  });

  animationChain.addOnComplete(() => {
    object.visible = false;
  });

  return animationChain;
};
